
  import { Component, Watch } from 'vue-property-decorator'
  import { GForm } from '@/components/forms/GForm'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import { plainToInstance } from 'class-transformer'
  import { fixDate, fixPrice, isValidNumber, updateNestedObject } from '@/utils/general'
  import LinkedPerson from '@/components/forms/fields/LinkedPerson.vue'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'
  import { Payment, PaymentType } from '@/entities/finance'
  import { Deal } from '@/entities/crm'
  import dayjs from 'dayjs'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import ProgressCell from '@/components/dataTables/cell/ProgressCell.vue'
  import GCostField from '@/components/core/input/GCostField.vue'

@Component({
  components: { GCostField, ProgressCell, GFiles, GAlert, GDatePicker, LinkedPerson, BaseForm },
  methods: { fixPrice },
  computed: {},
})
  export default class PaymentDetailForm extends GForm {
  payment: Payment = plainToInstance(Payment, {})
  deal: Deal = plainToInstance(Deal, {})
  title = 'ABONO'
  subtitle = 'Gestiona de forma sencilla y rápida las solicitudes de pago'
  paymentConfig = null
  showDetail = false
  startYear = 0
  totalAmount = 0
  totalPaymentsPaid = 0
  originalTotalPaymentsPaid = 0
  totalPaymentsValidated = 0
  totalValid = 0
  errorMessage = ''
  formData = {
    paymentType: null,
    bank: null,
    amount: null,
    paymentBackup: null,
    comment: null,
    payer: null,
    reference: null,
    paymentDate: dayjs().format('DD/MM/YYYY'),
    expirationDate: null,
    quotas: null,
    account: null,
  }

  declare $refs: {
    form: HTMLFormElement
    fieldClient: LinkedPerson
  };

  processValue = null
  record = null
  idProcess = null
  photoProperties = {}
  metadata = {}
  metadataCollection = {
    lead: null,
    deal: null,
    payment: null,
  }

  paymentAlert = {
    open: false,
    title: '',
  }

  paymentCancelAlert = {
    open: false,
    title: '¿Desea anular el abono?',
  }

  fields = {
    accounts: {
      properties: {
        label: 'Cuenta de abono',
        itemText: 'bankAccount',
        itemValue: 'id',
        returnObject: true,
        required: true,
        rules: this.fieldRequired,
      },
      items: [],
    },
    quotas: {
      properties: {
        require: true,
        rules: this.fieldRequired,
        label: 'Nro de cuotas',
      },
      items: [],
    },
    expirationDate: {
      properties: {
        require: true,
        rules: this.fieldRequired,
        label: 'Vencimiento',
      },
    },
    paymentType: {
      properties: {
        label: 'Forma de pago',
        itemText: 'description',
        itemValue: 'id',
        required: true,
        returnObject: true,
        hint: 'Cada pago es un registro',
      },
      items: [],
      rules: this.fieldRequired,
    },
    payer: {
      properties: {
        required: true,
        properties: {
          label: 'Pagador',
          rules: this.fieldRequired,
          itemText: 'formInfo',
          required: true,
          itemValue: 'id',
          clearable: true,
          returnObject: true,
        },
      },
      rules: this.fieldRequired,
      items: [],
    },
    bank: {
      properties: {
        label: 'Banco emisor',
        required: true,
        itemText: 'alias',
        itemValue: 'id',
        returnObject: true,
        rules: this.fieldRequired,
      },
      items: [],
    },
    amount: {
      properties: {
        label: 'Monto',
        required: true,
      },
      rules: this.fieldRequired,
    },
    paymentBackup: {
      properties: {
        label: 'Respaldo de pago',
        readonly: true,
        appendOuterIcon: 'mdi-paperclip',
        hint: 'Debe ser igual al monto indicado',
      },
      rules: this.fieldRequired,
    },
    order: {
      properties: {
        label: 'Comentario',
        appendOuterIcon: 'mdi-plus-circle-outline',
      },
    },
    reference: {
      properties: {
        label: '',
      },
    },
    paymentDate: {
      properties: {
        label: 'Fecha de pago',
        required: true,
      },
    },
  }

  async mounted () {
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'payment' } },
    })

    const config = process?.[0]?.config
    this.paymentConfig = config
    if (config.maxQuotas) {
      this.fields.quotas.items = Array.from({ length: config.maxQuotas }, (_, i) => i + 1)
    }
    this.idProcess = process[0].id
    await this.setMetadata()
    await this.getInfo()

    await this.setTheBackup()
  }

  async setMetadata () {
    const { metadata } = this.getForm('Lead', 'lead')
    const { metadata: metadataPayment } = this.getForm('Payment', 'payment')
    this.metadataCollection.lead = metadata

    this.metadataCollection.deal = metadataPayment

    const { uid, id } = this

    if (uid || id) {
      await this.getPaymentInfo(uid || id)
    }

    if (!this.isBreadCrumbPresent(this.title)) {
      this.setFormCrumbs(metadataPayment, 'Abono', Boolean(!isNaN(uid || id)))
    }

    this.fields.accounts.items = await this.fetchData({
      query: { name: 'find', model: 'FinancialAccount' },
      filter: {
        _and: [
          { category: { name: { _eq: 'income' } } },
          { person_account: { active: { _eq: true } } },
          { active: { _eq: true } },
        ],
      },
    })
  }

  async getPaymentInfo (id) {
    const paymentInfo = await this.fetchData({
      query: { name: 'fetch', model: 'Payment', params: { id } },
      force: true,
    })

    const deal = await this.fetchData({
      query: { name: 'find', model: 'Deal' },
      filter: { payments: { id: { _eq: id } } },
    })

    const stock = await this.fetchData({
      query: { name: 'find', model: 'Stock' },
      filter: { deals: { id: { _eq: deal[0].id } } },
    })

    this.deal = deal[0]
    this.deal.singlePayment = paymentInfo
    this.deal.stock = stock[0]
    this.formData.amount = paymentInfo.amount
    this.formData.paymentType = paymentInfo.type
    this.formData.bank = paymentInfo.issuingBank
    this.fields.bank.items = [paymentInfo.issuingBank].filter(Boolean)
    this.formData.reference = paymentInfo.reference
    this.formData.paymentDate = paymentInfo.date.format('DD/MM/YYYY')
    this.formData.comment = paymentInfo.comment
    this.formData.payer = paymentInfo.payer
    this.formData.expirationDate = paymentInfo.expiration?.format('DD/MM/YYYY')
    this.formData.quotas = paymentInfo.paymentCount
    this.formData.account = paymentInfo.financialAccount
    this.fields.payer.items = [paymentInfo.payer].filter(Boolean)
    this.payment = paymentInfo

    this.formData.paymentBackup = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: id } },
          { parameter: { process: { id: { _eq: this.idProcess } } } },
          { parameter: { name: { _eq: 'payment_receipt' } } },
        ],
      },
      force: true,
    })
  }

  async getInfo () {
    const { parentId, uid } = this

    if (!parentId && !uid) return null

    if (parentId) {
      const { deal } = await this.getParentData('Deal', parentId.toString())
      this.deal = deal
    }

    let excludePayment = ''
    if (this.backup?.reserveForm?.client) {
      excludePayment = 'bill_exchange'
    }
    this.fields.paymentType.items = await this.fetchData({
      query: { name: 'find', model: 'PaymentType' },
      filter: { name: { _nin: ['financing', 'vehicle_partially_paid', excludePayment] } },
    })

    this.fields.bank.items = await this.fetchData({
      query: { name: 'find', model: 'Person', order: { alias: 'asc' } },
      filter: { company_type: { name: { _eq: 'bank' } } },
      limit: 100,
    })

    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { _and: [{ process: { table_name: { _eq: 'payment' } } }, { file_type: { name: { _eq: 'photo' } } }] },
    })

    Object.assign(this.photoProperties, {
      accept: fileInfo[0].fileType.mimes,
      multiple: fileInfo[0].multiple,
      fileTypeId: fileInfo[0].fileType.id,
      name: fileInfo[0].name,
      label: fileInfo[0].description,
      required: true,
    })

    this.setDetails()
  }

  async setTheBackup () {
    const { backup, $route: { params: { id } } } = this

    if (!backup) {
      await this.close()
      return
    }
    const isClient = 'client' in backup && backup.client
    const isReserveForm = 'reserveForm' in backup && backup.reserveForm && backup.reserveForm.client
    const isDeal = 'deal' in backup && backup.deal
    const isPaymentForm = 'paymentForm' in backup && backup.paymentForm && id !== 'create'
    const isSaleOrder = 'saleForm' in backup && backup.saleForm && backup?.saleForm?.saleOrder
    const isSaleForm = 'saleForm' in backup && backup.saleForm
    const isReserve = 'reserve' in backup && backup.reserve
    const isConsignment = 'consignmentForm' in backup && backup.consignmentForm

    if (isPaymentForm) {
      this.formData = backup.paymentForm
    }

    if (isReserveForm && !isClient) {
      this.fields.payer.items = [backup.reserveForm.client]
    }

    if (isDeal) {
      this.deal = backup.deal
      this.setDetails()
    }

    if (isClient && isPaymentForm) {
      this.formData.payer = backup.client
      this.fields.payer.items = [backup.client]
    }

    await this.handleRecord(isSaleOrder, isReserve, isConsignment)

    this.setAmountValues(isSaleForm)
  }

  async handleRecord (isSaleOrder, isReserve, isConsignment) {
    if (isSaleOrder) {
      this.record = isSaleOrder
      this.processValue = await this.fetchData({
        query: { name: 'find', model: 'Process' },
        filter: { table_name: { _eq: 'sale_order' } },
      })
    }

    if (isReserve?.id) {
      this.record = isReserve
      this.processValue = await this.fetchData({
        query: { name: 'find', model: 'Process' },
        filter: { table_name: { _eq: 'reserve' } },
      })
    }

    if (isConsignment) {
      this.record = isConsignment
      this.processValue = await this.fetchData({
        query: { name: 'find', model: 'Process' },
        filter: { table_name: { _eq: 'purchase_order' } },
      })
      const idFinancialAccount = this.paymentConfig?.consignment?.id_financial_account_income
      this.fields.accounts.items = this.fields.accounts.items.filter(item => idFinancialAccount.includes(item.id))
    }
  }

  setAmountValues (saleForm) {
    if (!saleForm) return
    const {
      totalAmount,
      totalPaymentsPaid,
      totalPaymentsValidated,
      totalValid,
    } = saleForm

    this.totalAmount = totalAmount
    this.totalPaymentsPaid = totalPaymentsPaid
    this.originalTotalPaymentsPaid = totalPaymentsPaid
    this.totalPaymentsValidated = totalPaymentsValidated
    this.totalValid = totalValid

    this.fields.payer.items = [saleForm.client]
  }

  async goPerson () {
    const { formData, payment } = this
    this.saveBackup()
    const idPayment = payment?.id ? payment?.id.toString() : 'created'
    const idPerson = formData.payer?.id
    await this.$router.push({ name: 'generic-person-nested', params: { model: 'Payment', uid: idPayment, id: idPerson } })
  }

  saveBackup () {
    const { backup, formData, payment } = this

    if (backup) {
      if (payment?.id) {
        backup.payment = payment
      } else {
        backup.paymentForm = formData
      }
      backup.client = null
      this.setBackup(backup)
    } else {
      const back = {
        paymentForm: formData,
        payment,
      }

      if (!back?.payment) {
        delete back.payment
      } else {
        delete back.paymentForm
      }
      this.setBackup(back)
    }
  }

  async send () {
    const client = this.$refs.fieldClient
    const disabled = this.disabled
    if (!this.$refs.form.validate() || !client.$refs.form.validate() || disabled) {
      return
    }
    this.loadingForm = true
    const { payment, record } = this

    if (!payment.id && await this.findPayment(this.formData)) {
      this.loadingForm = false
      return
    } else if (record) {
      await this.handlePayment(payment)
    }
    this.saveBackup()
    await this.close()
  }

  async handlePayment (payment) {
    const { formData, record, processValue, deal } = this

    const paymentProperties = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { _and: [{ process: { table_name: { _eq: 'payment' } } }, { file_type: { name: { _eq: 'photo' } } }] },
    })

    const properties = {
      accept: paymentProperties[0].fileType.mimes,
      multiple: paymentProperties[0].multiple,
      fileTypeId: paymentProperties[0].fileType.id,
      name: paymentProperties[0].name,
      label: paymentProperties[0].description,
      required: paymentProperties[0].required,
      icon: paymentProperties[0].fileType.icon,
    }
    const status = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ default: { _eq: true } }, { process: { table_name: { _eq: 'payment' } } }] },
    })

    const paymentProcess = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'payment' } },
    })

    const fields = {
      id_payment_type: formData.paymentType.id,
      id: payment.id,
      id_process_status: status[0].id,
      id_process_record: record?.id,
      id_process: processValue[0].id,
      amount: formData.amount,
      reference: formData.reference,
      date: fixDate(formData.paymentDate),
      id_payer: formData.payer.id,
      id_issuing_bank: formData?.bank?.id,
      id_deal: deal.id,
      comment: formData.comment,
      expiration: fixDate(formData?.expirationDate),
      payment_count: formData.quotas,
      id_financial_account: formData?.account?.id,
    }

    if (!fields?.reference) {
      delete fields.reference
    }
    if (!fields?.id_issuing_bank) {
      delete fields.id_issuing_bank
    }
    if (!fields?.expiration) {
      delete fields.expiration
    }
    if (!fields?.payment_count) {
      delete fields.payment_count
    }

    const pay = await this.pushData({ model: 'Payment', fields })
    this.payment = pay
    if (formData.paymentBackup.length) {
      await this.handleFileType(formData.paymentBackup, { properties }, paymentProcess[0].id, pay.id)
    }
  }

  setDetails () {
    const { metadataCollection, deal } = this

    this.metadata = {
      data: deal,
      metadata: metadataCollection.deal,
    }

    this.showDetail = Boolean(deal.id)
  }

  get bindClient () {
    const { fields: { payer } } = this

    if (!payer) return {}

    return { ...payer.properties, items: payer.items }
  }

  async searchBank (input) {
    if (!input?.length || input.length < 2) return null
    const { metadataCollection: { deal } } = this

    if (!deal) return null
    const { fields } = deal

    const query = updateNestedObject(fields.bank.computed.queries.items.where, '_eq', input, ['company_type'])

    this.fields.bank.items = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { ...query },
      limit: 100,
      force: true,
    })
  }

  async searchPerson ({ input }) {
    if (!input?.length || input?.length < 2) return

    const { metadataCollection: { lead } } = this
    if (!lead) return
    const { fields } = lead
    const query = updateNestedObject(fields.client.computed.queries.items.where, '_eq', input, ['name_person_type'])

    this.fields.payer.items = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { ...query },
      force: true,
    })
  }

  get displayTransferFields () {
    const { formData: { paymentType } } = this

    return paymentType?.isTransfer
  }

  get displayCardFields () {
    const { formData: { paymentType } } = this
    return paymentType?.isDebitCard || paymentType?.isCreditCard
  }

  get displayCheckFields () {
    const { formData: { paymentType } } = this

    return paymentType?.isCheck || paymentType?.isOkView
  }

  get isExpirationDate () {
    const { formData: { paymentType } } = this
    return paymentType?.isCheck || paymentType?.isBillExchange
  }

  get isCreditCard () {
    const { formData: { paymentType } } = this
    return paymentType?.isCreditCard
  }

  get isCashFields () {
    const { formData: { paymentType } } = this

    return paymentType?.isCash
  }

  async findPayment (data) {
    let title
    if ([PaymentType.transfer, PaymentType.bank, PaymentType.okView, PaymentType.check].includes(data.paymentType?.name)) {
      title = await this.findBank(data)
    } else if ([PaymentType.creditCard, PaymentType.debitCard, PaymentType.billExchange].includes(data.paymentType?.name)) {
      title = await this.findReference(data)
    } else if (PaymentType.cash === data.paymentType?.name) {
      const { backup } = this

      if (backup?.reserveForm?.order) {
        const { reserveForm: { order } } = backup
        const orderFiltered = order.filter(item => item.type.name === PaymentType.cash)
        title = this.findRecentPayment(orderFiltered, data)
      }
    }

    if (title) {
      this.paymentAlert = {
        open: true,
        title,
      }
      return true
    }
  }

  findDuplicatePayment (orders: any[], data: any, compareFunc: (order: any, data: any) => boolean): string | undefined {
    const exist = orders.some(order => compareFunc(order, data))
    if (exist) {
      return 'En la transacción actual ya existe un pago identico ¿deseas registrarlo?'
    }
  }

  findRecentBankPayment (orders: any[], data: any): string | undefined {
    return this.findDuplicatePayment(orders, data, (order, data) =>
      order.amount === data.amount &&
      order.date === data.paymentDate &&
      order.issuingBank.id === data.bank?.id
    )
  }

  findRecentPayment (orders: any[], data: any): string | undefined {
    return this.findDuplicatePayment(orders, data, (order, data) =>
      order.amount === data.amount &&
      order.date === data.paymentDate
    )
  }

  async findReference (val) {
    if (val.amount && val.reference) {
      const resp = await this.fetchData({
        query: { name: 'find', model: 'Payment' },
        filter: {
          _and: [
            { reference: { _eq: val.reference } },
            { amount: { _eq: val.amount } },
            { _or: [{ id_closing_reason: { _is_null: true } }, { closing_reason: { type: { name: { _neq: 'canceled' } } } }] },
          ],
        },
      })

      if (resp.length) {
        return 'Ya existe un pago con la misma referencia y el mismo monto ¿deseas registrarlo?'
      }
    }

    const { backup } = this

    if (backup?.reserveForm?.order) {
      const { reserveForm: { order } } = backup
      const orderFiltered = order.filter(item => [PaymentType.creditCard, PaymentType.debitCard, PaymentType.billExchange].includes(item.type.name))
      return this.findRecentPayment(orderFiltered, val)
    }
  }

  async findBank (val) {
    if (val?.bank?.id && val.amount && val.reference) {
      const resp = await this.fetchData({
        query: { name: 'find', model: 'Payment' },
        filter: {
          _and: [
            { id_issuing_bank: { _eq: val.bank.id } },
            { reference: { _eq: val.reference } },
            { amount: { _eq: val.amount } },
            { _or: [{ id_closing_reason: { _is_null: true } }, { closing_reason: { type: { name: { _neq: 'canceled' } } } }] },
          ],
        },
      })

      if (resp.length) {
        return 'Ya existe un pago de ese banco, con la misma referencia y el mismo monto ¿deseas registrarlo?'
      }
    }

    const { backup } = this

    if (backup?.reserveForm?.order) {
      const { reserveForm: { order } } = backup
      const orderFiltered = order.filter(item => [PaymentType.transfer, PaymentType.bank, PaymentType.okView, PaymentType.check].includes(item.type.name))

      return this.findRecentBankPayment(orderFiltered, val)
    }
  }

  get change () {
    const { formData } = this

    return JSON.stringify(formData)
  }

  cleanPayment () {
    this.formData.amount = ''
    this.formData.bank = null
    this.formData.reference = ''
    this.paymentAlert = {
      open: false,
      title: '',
    }
  }

  sendPayment () {
    this.paymentAlert = {
      open: false,
      title: '',
    }
    this.saveBackup()
    this.close()
  }

  async cancelPayment () {
    this.paymentCancelAlert.open = true
  }

  async confirmCancelPayment () {
    const status = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'payment' } } }, { status: { name: { _eq: 'closed' } } }] },
    })

    const filterSuccess = {
      _and: [
        { type: { name: { _eq: 'canceled' } } },
        { status: { process: { table_name: { _eq: 'payment' } } } },
      ],
    }

    const closingReason = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: filterSuccess,
    })

    await this.pushData({
      model: 'Payment',
      fields: { id: this.payment.id, id_process_status: status[0].id, id_closing_reason: closingReason[0].id },

    })
    this.paymentAlert.open = false
    await this.close()
  }

  get watchPayment () {
    const { formData: { amount }, payment } = this
    return {
      amount, payment,
    }
  }

  @Watch('watchPayment', { immediate: true, deep: true })
  handleAmountPayment (val) {
    if (val?.payment?.id && isValidNumber(val.amount)) {
      this.totalPaymentsPaid = this.originalTotalPaymentsPaid + (Number(val.amount) - val.payment.amount)
    } else if (isValidNumber(val.amount)) {
      this.totalPaymentsPaid = this.originalTotalPaymentsPaid + Number(val.amount)
    } else {
      this.totalPaymentsPaid = this.originalTotalPaymentsPaid
    }
  }

  get totalPercentagePaid () {
    const { totalAmount, totalPaymentsPaid } = this

    return ((totalPaymentsPaid * 100) / totalAmount).toFixed(2)
  }

  get totalPercentageValidate () {
    const { totalPaymentsPaid, totalPaymentsValidated } = this

    return ((totalPaymentsValidated * 100) / totalPaymentsPaid).toFixed(2)
  }

  get total () {
    const { totalPaymentsPaid, totalAmount } = this
    const total = totalAmount - totalPaymentsPaid

    if (total < 0) {
      this.errorMessage = `La suma de los pagos no puede ser mayor al total a cancelar`
    } else {
      this.errorMessage = ''
    }

    return total
  }

  get calculateLabel () {
    const { formData: { paymentType } } = this
    if ([PaymentType.check].includes(paymentType?.name)) {
      return 'N° de serie'
    }
    return 'Número referencia'
  }

  get isCash () {
    const { formData } = this

    const cash = formData.paymentType?.isCash

    if (cash) {
      this.formData.account = null
    }

    return cash && formData.paymentType?.id
  }

  get disabled () {
    const { payment } = this

    return Boolean(payment?.closingReason?.id)
  }
  }
